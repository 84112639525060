<template>
  <v-card color="white" class="pa-8">
    <v-card-text class="px-0">
      <div>
        <section id="overview">
          <div class="bsat__title">{{ $t("title") }}</div>
          <div class="bsat__paragraph">{{ $t("paragraph") }}</div>
        </section>

        <!-- ************************ IMPLEMENTATION RECOMMENDATIONS 1 ********************************* -->

        <section id="implementationRecommendations">
          <div class="bsat__subtitle mb-4">
            <strong>{{ $t("implementationRecommendations.title") }}</strong>
          </div>

          <ReadMore :intro="$t('implementationRecommendationReadMore')">
            <v-row align="center" v-if="$vuetify.breakpoint.smAndUp">
              <v-col cols="12" sm="4"
                ><div class="font-weight-bold dark-text">Task</div></v-col
              >
              <v-col cols="12" sm="4"
                ><div class="font-weight-bold dark-text">
                  Task Leader
                </div></v-col
              >
              <v-col cols="12" sm="4"
                ><div class="font-weight-bold dark-text pl-2">
                  Proposed Methods
                </div></v-col
              >
            </v-row>
            <v-divider
              class="bg-dark"
              v-if="$vuetify.breakpoint.smAndUp"
            ></v-divider>

            <!-- ************************* TASK 1 ********************************************** -->
            <v-row>
              <v-col cols="12" sm="4">
                <i18n
                  path="implementationRecommendations.task1.text"
                  tag="div"
                  class="bsat__paragraph mb-4"
                >
                  <template #title>
                    <strong>{{
                      $t("implementationRecommendations.task1.title")
                    }}</strong>
                  </template>
                </i18n>
              </v-col>
              <v-col cols="12" sm="4">
                <div
                  class="bsat__paragraph mb-4"
                  v-if="$vuetify.breakpoint.xsOnly"
                >
                  <strong>{{
                    $t("implementationRecommendations.task2.taskLeader.title")
                  }}</strong>
                </div>
                <ul>
                  <li class="bsat__paragraph">
                    {{
                      $t("implementationRecommendations.task1.taskLeader.text")
                    }}
                  </li>
                </ul>
              </v-col>
              <v-col cols="12" sm="4">
                <div
                  class="bsat__paragraph mb-4"
                  v-if="$vuetify.breakpoint.xsOnly"
                >
                  <strong>{{
                    $t(
                      "implementationRecommendations.task1.proposedMethods.title"
                    )
                  }}</strong>
                </div>
                <div>
                  <ul>
                    <li
                      class="bsat__paragraph mb-4"
                      v-for="(item, i) in proposedMethodsTask1"
                      :key="i"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>

            <v-divider
              class="bg-dark"
              v-if="$vuetify.breakpoint.smAndUp"
            ></v-divider>

            <!-- ************************* TASK 2 ********************************************** -->
            <v-row>
              <v-col cols="12" sm="4">
                <i18n
                  path="implementationRecommendations.task2.text"
                  tag="div"
                  class="bsat__paragraph mb-4"
                >
                  <template #title>
                    <strong>{{
                      $t("implementationRecommendations.task2.title")
                    }}</strong>
                  </template>
                </i18n>
              </v-col>
              <v-col cols="12" sm="4">
                <div
                  class="bsat__paragraph mb-4"
                  v-if="$vuetify.breakpoint.xsOnly"
                >
                  <strong>{{
                    $t("implementationRecommendations.task2.taskLeader.title")
                  }}</strong>
                </div>
                <ul>
                  <li class="bsat__paragraph">
                    {{
                      $t("implementationRecommendations.task2.taskLeader.text")
                    }}
                  </li>
                </ul>
              </v-col>
              <v-col cols="12" sm="4">
                <div
                  class="bsat__paragraph mb-4"
                  v-if="$vuetify.breakpoint.xsOnly"
                >
                  <strong>{{
                    $t(
                      "implementationRecommendations.task2.proposedMethods.title"
                    )
                  }}</strong>
                </div>
                <div>
                  <ul>
                    <li
                      class="bsat__paragraph mb-4"
                      v-for="(item, i) in proposedMethodsTask2"
                      :key="i"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>

            <v-divider
              class="bg-dark"
              v-if="$vuetify.breakpoint.smAndUp"
            ></v-divider>
            <!-- ************************* TASK 3 ********************************************** -->
            <v-row>
              <v-col cols="12" sm="4">
                <i18n
                  path="implementationRecommendations.task3.text"
                  tag="div"
                  class="bsat__paragraph mb-4"
                >
                  <template #title>
                    <strong>{{
                      $t("implementationRecommendations.task3.title")
                    }}</strong>
                  </template>
                </i18n>
              </v-col>
              <v-col cols="12" sm="4">
                <div
                  class="bsat__paragraph mb-4"
                  v-if="$vuetify.breakpoint.xsOnly"
                >
                  <strong>{{
                    $t("implementationRecommendations.task2.taskLeader.title")
                  }}</strong>
                </div>
                <ul>
                  <li class="bsat__paragraph">
                    {{
                      $t("implementationRecommendations.task3.taskLeader.text")
                    }}
                  </li>
                </ul>
              </v-col>
              <v-col cols="12" sm="4">
                <div
                  class="bsat__paragraph mb-4"
                  v-if="$vuetify.breakpoint.xsOnly"
                >
                  <strong>{{
                    $t(
                      "implementationRecommendations.task3.proposedMethods.title"
                    )
                  }}</strong>
                </div>
                <div>
                  <ul>
                    <li
                      class="bsat__paragraph mb-4"
                      v-for="(item, i) in proposedMethodsTask3"
                      :key="i"
                    >
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </ReadMore>
        </section>

        <!-- <section id="implementationRecommendations">
          <div class="bsat__subtitle mb-4">
            <strong>{{ $t("implementationRecommendations.title") }}</strong>
          </div>

          <ReadMore :intro="$t('implementationRecommendationReadMore')">
            <i18n
              path="implementationRecommendations.task1.text"
              tag="div"
              class="bsat__paragraph mb-4"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.task1.title")
                }}</strong>
              </template>
            </i18n>

            <i18n
              path="implementationRecommendations.task1.taskLeader.text"
              tag="div"
              class="bsat__paragraph mb-4"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.task1.taskLeader.title")
                }}</strong>
              </template>
            </i18n>

            <div class="bsat__paragraph mb-4">
              <strong>{{
                $t("implementationRecommendations.task1.proposedMethods.title")
              }}</strong>
            </div>

            <div>
              <ul>
                <li
                  class="bsat__paragraph mb-4"
                  v-for="(item, i) in proposedMethodsTask1"
                  :key="i"
                >
                  {{ item }}
                </li>
              </ul>
            </div>

            <v-divider class="bg-dark"></v-divider>
            <i18n
              path="implementationRecommendations.task2.text"
              tag="div"
              class="bsat__paragraph mb-4 mt-8"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.task2.title")
                }}</strong>
              </template>
            </i18n>

            <i18n
              path="implementationRecommendations.task2.taskLeader.text"
              tag="div"
              class="bsat__paragraph mb-4"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.task2.taskLeader.title")
                }}</strong>
              </template>
            </i18n>

            <div class="bsat__paragraph mb-4">
              <strong>{{
                $t("implementationRecommendations.task2.proposedMethods.title")
              }}</strong>
            </div>

            <div>
              <ul>
                <li
                  class="bsat__paragraph mb-4"
                  v-for="(item, i) in proposedMethodsTask2"
                  :key="i"
                >
                  {{ item }}
                </li>
              </ul>
            </div>

            <v-divider class="bg-dark"></v-divider>
            <i18n
              path="implementationRecommendations.task3.text"
              tag="div"
              class="bsat__paragraph mb-4 mt-8"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.task3.title")
                }}</strong>
              </template>
            </i18n>

            <i18n
              path="implementationRecommendations.task3.taskLeader.text"
              tag="div"
              class="bsat__paragraph mb-4"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.task3.taskLeader.title")
                }}</strong>
              </template>
            </i18n>

            <div class="bsat__paragraph mb-4">
              <strong>{{
                $t("implementationRecommendations.task3.proposedMethods.title")
              }}</strong>
            </div>

            <div>
              <ul>
                <li
                  class="bsat__paragraph mb-4"
                  v-for="(item, i) in proposedMethodsTask3"
                  :key="i"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </ReadMore>
        </section> -->

        <!-- ********************* RECOMMENDED RESOURCES ********************* -->
        <section id="recommendedResources">
          <div class="steps__subtitle bsat__subtitle my-4">
            {{ $t("recommendedResources.title") }}
          </div>

          <!-- <div class="bsat__paragraph mt-n3 mb-6">
            {{ $t("recommendedResourcesIntro") }}
          </div> -->

          <ul class="ml-0 ml-sm-4 bsat__ul">
            <li>
              <i18n
                path="recommendedResources.paragraph.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <a @click="goToStep(3)">{{
                    $t("recommendedResources.paragraph.link")
                  }}</a>
                </template>
              </i18n>
            </li>

            <li>
              <div class="bsat__paragraph">
                {{ $t("recommendedResources.paragraph2") }}
              </div>
            </li>

            <li>
              <i18n
                path="recommendedResources.financingTools.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #title>
                  <strong>
                    {{ $t("recommendedResources.financingTools.title") }}
                  </strong>
                </template>
              </i18n>

              <v-btn
                outlined
                width="150"
                height="26"
                class="bsat__btn"
                color="dark"
                to="/repository-of-financing-instruments-and-tools"
                >{{ $t("visit") }}</v-btn
              >
              <!-- <a
                :href="require('@/assets/files/financial_tools.docx').default"
                target="_blank"
                download
              >
                <v-btn
                  class="bsat__btn"
                  color="dark"
                  dark
                  depressed
                  width="150"
                  height="26"
                  >{{ $t("download") }}</v-btn
                >
              </a> -->
            </li>

            <!-- <li>
              <i18n
                path="recommendedResources.guidelines.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #title>
                  <strong>{{
                    $t("recommendedResources.guidelines.title")
                  }}</strong>
                </template>
              </i18n>

              <a :href="guidelinesFile" target="_blank" download>
                <v-btn
                  depressed
                  width="150"
                  height="26"
                  class="bsat__btn"
                  dark
                  color="dark"
                  >{{ $t("download") }}</v-btn
                >
              </a>
            </li> -->

            <li>
              <div class="bsat__paragraph">
                {{ $t("recommendedResources.paragraph3") }}
              </div>
            </li>

            <li>
              <div class="bsat__paragraph">
                {{ $t("recommendedResources.paragraph4") }}
              </div>
            </li>
          </ul>
        </section>

        <i18n
          class="mt-8"
          tag="div"
          path="downloadAll.text"
          style="font-family: Montserrat"
        >
          <template #link>
            <a class="dark-text mt-8" :href="zip" target="_blank" download>
              {{ $t("downloadAll.link") }}
            </a>
          </template>
        </i18n>
      </div>
    </v-card-text>
  </v-card>
</template>

<i18n>
{
  "en": {
    "implementationRecommendations": {
      "task1": {
        "proposedMethods": {
          "title": "Proposed Methods",
          "unorderedList": [
            "List of policy and financial mechanism already included in the bioeconomy strategy.",
            "Revise the evaluation of their use and effectivity, taking into account KPIs from the monitoring system used in the region"
          ]
        },
        "taskLeader": {
          "text": "Strategy update working group. ",
          "title": "Task Leader"
        },
        "text": "{title}: Revise current policy and financial mechanism being used in the regional bioeconomy strategy and roadmap",
        "title": "Task 1"
      },
      "task2": {
        "proposedMethods": {
          "title": "Proposed Methods",
          "unorderedList": [
            "Revise the overview of policies throughout the value chain and analysis of good policies. Identify those not used or not exploited enough in the region.",
            "Revise the overview and good examples you used in your region in the past when developing the current bioeconomy strategy.",
            "Discussion/Focus-group between members of strategy update working group and selected experts.  "
          ]
        },
        "taskLeader": {
          "text": "Strategy update working group and selected experts. ",
          "title": "Task Leader"
        },
        "text": "{title}: Gap analysis of policy and financial mechanisms for the region to select those that could support updated priority areas, goals and activities in the roadmap",
        "title": "Task 2"
      },
      "task3": {
        "proposedMethods": {
          "title": "Proposed Methods",
          "unorderedList": [
            "Discussion/Focus-group between members of strategy update working group",
            "Feedback rounds (as many as necessary) with decision-level of interministerial/interdepartmental group and advisory group. "
          ]
        },
        "taskLeader": {
          "text": "Strategy update working group. ",
          "title": "Task Leader"
        },
        "text": "{title}: Assign specific mechanisms to the specific planned actions in the roadmap",
        "title": "Task 3"
      },
      "title": "Implementation Recommendations"
    },
    "paragraph": "The newly identified priority areas should be designed and supported accordingly to the required financial and regulatory environments to align resources and goals. It is then very timely to update and re-assess the financial and policy mechanisms used in the region in the past, and the extent to which those existing tools are still valid and adapted to the needs of the priority areas. This step is then very important to allocate required elements for long-lasting planning analysis. ",
    "recommendedResources": {
      "financingTools": {
        "text": "{title}: At this stage, it is instrumental to review the work accomplished so far with general remarks to complement and “help you think” against your own developments. These documents summarise general comments learnt from experiences in the same domain. Thus, it is worth keeping those general comments as a reference to close this assessment and prior to moving into the next steps. Specially consult resources 6 and 7.",
        "title": "Repository of supporting documents and tools for the implementation of EU financial instruments in the area of bioeconomy"
      },
      "guidelines": {
        "text": "{title} Use the guidelines for the design of new financial instruments from POWER4BIO, to generate if necessary a novel financial instrument for the support of defined priority areas and corresponding main goals. Consider the gap analysis of financial instruments as a prerequisite step.",
        "title": "Guidelines to financial instruments design:"
      },
      "paragraph": {
        "link": "Step 3",
        "text": "Updated priority areas and goals ({link})"
      },
      "paragraph2": "Overview of policies throughout the value chain and analysis of good policies ",
      "paragraph3": "References found on the exemplary successful business cases ",
      "paragraph4": "Evaluation of the use of financial and policy mechanism for the regional bioeconomy strategy (up to date)",
      "title": "Recommended resources"
    },
    "title": "Step 2: Assign new supporting policy, financial mechanisms and resources to the updated strategy and roadmap.",
    "downloadAll": {
			"link": "click here",
			"text": "*To download all the materials of Phase 3 and Phase 4 as a zip file please {link}"
		}
  }
}
</i18n>

<script>
import { EventBus } from "@/utils/EventBus";
import ReadMore from "@/components/ReadMore";

export default {
  name: "UpdatePhase4Step2",
  components: {
    ReadMore
  },
  data() {
    return {
      // TODO: 1.3.4_3
      guidelinesFile: "",
      zip: require("@/assets/files/Phase3_Phase4_common_resources.zip").default
    };
  },
  computed: {
    summaryUnorderedList() {
      return Object.values(this.$t("summary.unorderedList"));
    },
    proposedMethodsTask1() {
      return Object.values(
        this.$t(
          "implementationRecommendations.task1.proposedMethods.unorderedList"
        )
      );
    },
    proposedMethodsTask2() {
      return Object.values(
        this.$t(
          "implementationRecommendations.task2.proposedMethods.unorderedList"
        )
      );
    },
    proposedMethodsTask3() {
      return Object.values(
        this.$t(
          "implementationRecommendations.task3.proposedMethods.unorderedList"
        )
      );
    }
  },
  methods: {
    goToTab(tab) {
      EventBus.$emit("goToTab", tab);
    },
    goToStep(step) {
      EventBus.$emit("goToStep", step);
    },
    getSummaryChildren(obj) {
      return Object.values(obj);
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/main";
</style>
